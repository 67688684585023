<template>
    <v-navigation-drawer
        :value="isFiltersActive"
        temporary
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
        app
        @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
        <v-card height="100%">
            <div class="drawer-header d-flex align-center mb-4">
                <span class="font-weight-semibold text-base text--primary">{{ $t('Filters') }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
                    <v-icon size="22">
                        {{ mdiClose }}
                    </v-icon>
                </v-btn>
            </div>

            <v-card-text>
                <v-btn @click="deleteFilters" expanded class="d-flex ml-auto text-capitalize" text color="error">
                    {{ $t('DeleteFiltes') }}
                    <v-icon right>
                        {{ mdiCloseBoxOutline }}
                    </v-icon>
                </v-btn>
                <div class="mt-4">
                    <v-range-slider
                        persistent-hint
                        v-model="query.filters.capacity.$between"
                        min="0"
                        max="100"
                        thumb-label
                        :label="$t('Lotation')"
                        class="mt-4">
                    </v-range-slider>

                    <v-select
                        hide-details="auto"
                        :items="transportTypes"
                        :item-text="'name'"
                        :item-value="'value'"
                        v-model="query.filters.type.$containsi"
                        filled
                        attach
                        :label="$t('Type')"></v-select>
                    <div class="mt-6">
                        <v-btn @click="applyFilters" color="primary" class="me-3" type="submit">
                            {{ $t('Buttons.Filter') }}
                        </v-btn>
                        <v-btn
                            @click="$emit('update:is-add-new-user-sidebar-active', false)"
                            color="secondary"
                            outlined>
                            {{ $t('Buttons.Cancel') }}
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    import { mdiClose, mdiCloseBoxOutline } from '@mdi/js';
    import transportStoreModule from '../transportStoreModule';
    import useTransportsList from '../transports-list/useTransportsList';

    export default {
        data() {
            return {
                transportStoreModule,
                query: {},
                transportTypes: [
                    { name: '', value: 'roadway' },
                    { name: '', value: 'railway' },
                    { name: '', value: 'subway' },
                ],
            };
        },
        model: {
            prop: 'isFiltersActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isFiltersActive: {
                type: Boolean,
                required: true,
            },

            transports: {
                type: Array,
                required: true,
            },
        },

        setup() {
            const { transprtListTable, fetchTransportsList } = useTransportsList();

            return {
                mdiClose,
                mdiCloseBoxOutline,
                transprtListTable,
                fetchTransportsList,
            };
        },
        created() {
            this.query = this.$store.getters['app-transport/getTransportsQuery'];
            this.fetchTransportsList();
            this.transportTypes[0].name = this.$t('Roadway');
            this.transportTypes[1].name = this.$t('Railway');
            this.transportTypes[2].name = this.$t('Subway');
        },

        methods: {
            applyFilters() {
                this.query.pagination.page = 1;
                this.$store.commit('app-transport/setTransportsQuery', this.query);
                this.$emit('setTransportQuery');
                this.$emit('update:is-add-new-user-sidebar-active', false);
            },
            deleteFilters() {
                this.query.filters.capacity.$between = [0, 100];
                this.query.filters.type.$containsi = '';
            },
        },
    };
</script>
